import Level1 from "./Level1";
import Level2 from "./Level2";
import Level3 from "./Level3";
import Level4 from "./Level4";
import Level5 from "./Level5";

export default class Factory {
    constructor(level, options, maxDifficulty) {
        switch (level) {
            case 1:
                return new Level1(options);
            case 2:
                return new Level2(options);
            case 3:
                return new Level3(options);
            case 4:
                return new Level4(options, maxDifficulty);
            case 5:
                return new Level5(options, maxDifficulty);
            default:
                return new Error("no existe ese nivel");
        }
    }
}