import React, { useState } from "react";
import { TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";

export default function SignUpForm() {
  const [data, setData] = useState({
    user: "",
    password: "",
    code: "",
    adminCode: "",
  });

  const [isValid, setIsValid] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");

  const navigate = useNavigate();

  function handleChange(event) {
    setData((prevData) => ({
      ...prevData,
      [event.target.name]: event.target.value,
    }));
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    const res = await fetch(`${process.env.REACT_APP_API_URL}register`, {
      method: "POST",
      mode: "cors",
      body: JSON.stringify(data),
      headers: { "Content-type": "application/json; charset=UTF-8" },
    });
    if (res.status === 200) {
      alert("Usuario registrado");
      navigate("/login");
    } else if (res.status === 400) {
      setIsValid(false);
      setErrorMessage("El usuario ya existe");
    } else if (res.status === 401) {
      setIsValid(false);
      setErrorMessage("Código incorrecto");
    }
  };

  return (
    <div className="max-w-md mx-auto my-8 p-6 bg-white rounded-lg shadow-md">
      <h1 className="text-2xl font-semibold mb-6">Regístrate</h1>
      <form className="space-y-4" onSubmit={handleSubmit}>
        <input
          type="text"
          placeholder="Usuario"
          onChange={handleChange}
          name="user"
          value={data.user}
          required
          className="w-full px-4 py-2 border rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
        <input
          type="password"
          placeholder="Contraseña"
          onChange={handleChange}
          name="password"
          value={data.password}
          required
          className="w-full px-4 py-2 border rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
        <input
          type="password"
          placeholder="Código"
          onChange={handleChange}
          name="code"
          value={data.code}
          required
          className="w-full px-4 py-2 border rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
        <input
          type="password"
          placeholder="Código de administrador"
          onChange={handleChange}
          name="adminCode"
          value={data.adminCode}
          className="w-full px-4 py-2 border rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
        <button
          type="submit"
          className="w-full px-4 py-2 bg-blue-500 text-white rounded-lg shadow-sm hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
        >
          Registrarse
        </button>
        <p className="mt-3 mb-0 text-center">
          ¿Ya tienes usuario? <a href="/login" className="text-blue-500 hover:underline">Iniciar sesión</a>
        </p>
      </form>
      {!isValid && (
        <div className="mt-4 p-2 bg-red-100 text-red-800 border border-red-300 rounded">
          {errorMessage}
        </div>
      )}
    </div>
  );
}
