import React, { useContext } from 'react';
import ConfigContext from '../contexts/ConfigContext';

export default function SelectMaxQuestions() {
  const { maxQuestions, setMaxQuestions } = useContext(ConfigContext);

  const handleSliderChange = (event) => {
    const newMaxQuestions = parseInt(event.target.value, 10);
    setMaxQuestions(newMaxQuestions);
  };

  return (
    <div className="p-4 w-full">
      <label
        htmlFor="maxQuestions"
        className="block text-lg font-medium text-gray-700 mb-2"
      >
        Número de preguntas: {maxQuestions}
      </label>
      <input
        type="range"
        id="maxQuestions"
        min="1"
        max="20"
        step="1"
        value={maxQuestions}
        onChange={handleSliderChange}
        className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer range-slider"
      />
      <style jsx>{`
        .range-slider::-webkit-slider-thumb {
          appearance: none;
          width: 20px;
          height: 20px;
          background: #4a90e2;
          cursor: pointer;
          border-radius: 50%;
          box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
        }

        .range-slider::-moz-range-thumb {
          width: 20px;
          height: 20px;
          background: #4a90e2;
          cursor: pointer;
          border-radius: 50%;
          box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
        }
      `}</style>
    </div>
  );
}
