import { createContext, useState } from "react";

const ConfigContext = createContext();

export function ConfigProvider({ children }) {
  const [configuration, setConfiguration] = useState(
    new Map()
    // .set("Space", {
    // 	categoria: "fruta",
    // 	color: "amarillo",
    // 	forma: "alargada",
    // 	nombre: "banana",
    // })
    // .set("ArrowDown", {
    // 	categoria: "fruta",
    // 	color: "rojo",
    // 	forma: "redonda",
    // 	nombre: "manzana",
    // })
  );

  const [maxQuestions, setMaxQuestions] = useState(10);
  const [maxDifficulty, setMaxDifficulty] = useState(3);

  const addItem = (key, item) => {
    const { __v, _id, ...newItem } = item;
    setConfiguration((prevConfig) => {
      const newConfig = new Map(prevConfig);
      newConfig.set(key, newItem);
      return newConfig;
    });
  };

  const removeItem = (key) => {
    setConfiguration((prevConfig) => {
      const newConfig = new Map(prevConfig);
      newConfig.delete(key);
      return newConfig;
    });
  };

  const resetItems = () => {
    // setConfiguration(
    // 	new Map()
    // 		.set("Space", {
    // 			categoria: "fruta",
    // 			color: "amarillo",
    // 			forma: "alargada",
    // 			nombre: "banana",
    // 		})
    // 		.set("ArrowDown", {
    // 			categoria: "fruta",
    // 			color: "rojo",
    // 			forma: "redonda",
    // 			nombre: "manzana",
    // 		})
    // );
  };

  const setPrevConfiguration = (prevConfig) => {
    if (prevConfig.size !== 0) setConfiguration(prevConfig);
  };

  return (
    <ConfigContext.Provider
      value={{
        configuration,
        addItem,
        removeItem,
        resetItems,
        setPrevConfiguration,
        maxQuestions,
        setMaxQuestions,
        maxDifficulty,
        setMaxDifficulty,
      }}
    >
      {children}
    </ConfigContext.Provider>
  );
}

export default ConfigContext;
