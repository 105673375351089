import React from 'react';

export default function Log() {
  async function downloadLog() {
    const token = localStorage.getItem('token');
    fetch(`${process.env.REACT_APP_API_URL}log`, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
      .then((data) => data.blob())
      .then((blob) => {
        const pdfUrl = window.URL.createObjectURL(blob);
        window.open(pdfUrl, '_blank');
      });
  }

  return (
    <div>
      <p className="text-gray-700 mb-2">
        Podrá ver los resultados de sus sesiones descargando el siguiente archivo.
      </p>
      <button
        type="button"
        className="flex items-center px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-400"
        onClick={downloadLog}
      >
        Descargar
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          className="bi bi-file-earmark-arrow-down-fill ml-2"
          viewBox="0 0 16 16"
        >
          <path d="M9.293 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.707A1 1 0 0 0 13.707 4L10 .293A1 1 0 0 0 9.293 0zM9.5 3.5v-2l3 3h-2a1 1 0 0 1-1-1zm-1 4v3.793l1.146-1.147a.5.5 0 0 1 .708.708l-2 2a.5.5 0 0 1-.708 0l-2-2a.5.5 0 0 1 .708-.708L7.5 11.293V7.5a.5.5 0 0 1 1 0z" />
        </svg>
      </button>
    </div>
  );
}
