import React from "react";

export default function Footer() {
    return (
        <footer className="bg-gray-200 py-4 flex justify-between items-center px-6 shadow-md fixed bottom-0 left-0 w-full md:static">
            <p className="text-black text-sm font-light">
                Todos los derechos reservados &copy; 2023
            </p>
            <div className="flex space-x-4">
                <a href="https://www.unicen.edu.ar/">
                    <img src="/unicen.png" alt="Imagen 1" className="w-28" />
                </a>
                <a href="https://exa.unicen.edu.ar/">
                    <img src="/exactas.png" alt="Imagen 2" className="w-28" />
                </a>
            </div>
        </footer>
    );
}
