import { AbstractLevel } from "./AbstractLevel";

export default class Level4 extends AbstractLevel {
  constructor(options, maxDifficulty) {
    super();
    this.setOptions(options);
		this.maxDifficulty = maxDifficulty;
    this.difficulty = 0;
    this.level = 4;
  }

  setOptions(options) {
    // eslint-disable-next-line
    for (const [key, value] of options) {
      this.options.push(...Object.values(value));
    }
  }

  getOption() {
    this.option = [];
    let index;
    if (this.difficulty < this.maxDifficulty) {
      this.difficulty++;
    }
    for (let i = 0; i < this.difficulty; i++) {
      index = Math.floor(Math.random() * this.options.length);
      this.option.push(this.options[index]);
    }
    return this.option;
  }

  checkAnswer(pressedKeys, configuration) {
    let success = false;
    let i = 0;
    while (i < pressedKeys.length) {
      if (configuration.get(pressedKeys[i])) {
        success = Object.values(configuration.get(pressedKeys[i])).includes(
          this.option[i]
        );
      } else success = false;
      if (!success) {
        super.updateMap(this.option[i]);
        break;
      }
      i++;
    }
    return success;
  }

  getText() {
    let question = "Presione ";
    question += this.option[0].string;
    for (let i = 1; i < this.option.length; i++) {
      question += ", luego " + this.option[i].string;
    }
    return question;
  }
}
