import { AbstractLevel } from "./AbstractLevel";

export default class Level3 extends AbstractLevel {
	constructor(options) {
		super();
		this.setOptions(options);
		this.difficulty = 2;
		this.level = 3;
	}

	setOptions(options) {
		// eslint-disable-next-line
		for (const [key, value] of options) {
			this.options.push(...Object.values(value));
		}
	}

	getOption() {
		this.option = [];
		let index;
		for (let i = 0; i < 2; i++) {
			index = Math.floor(Math.random() * this.options.length);
			this.option.push(this.options[index]);
		}
		return this.option;
	}

	checkAnswer(pressedKeys, configuration) {
		const values = Object.values(configuration.get(pressedKeys[0])).concat(
			Object.values(configuration.get(pressedKeys[1]))
		);
		const valueSet = new Set(values.map(obj => obj.string));
		const optionSet = new Set(this.option.map(obj => obj.string));
		let diff = [];
		for (const value of optionSet) {
			!valueSet.has(value) && diff.push(value)
		}
		return true;
	}

	getText() {
		return `presione ${this.option[0].string} y ${this.option[1].string}`; 
	}
}
