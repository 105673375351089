import { AbstractLevel } from "./AbstractLevel";

export default class Level1 extends AbstractLevel {
	constructor(options) {
		super();
		this.setOptions(options);
		this.difficulty = 1;
		this.level = 1;
	}

	setOptions(options) {
		// eslint-disable-next-line
		for (const [key, value] of options) {
			this.options.push(value.nombre);
		}
	}

	getText() {
		return `Presione ${this.option.string}`;
	}
}
