export class AbstractLevel {
	constructor() {
		if (new.target === AbstractLevel) {
			throw new TypeError(
				"No se puede instanciar una clase abstracta directamente"
			);
		}
		this.options = [];
		this.option = [];
	}

	setOptions() {
		throw new Error("los metodos abstractos no tienen implementacion");
	}

	getOption() {
		let index = Math.floor(Math.random() * this.options.length);
		let newOption = this.options[index];
		while (this.option === newOption) {
			index = Math.floor(Math.random() * this.options.length);
			newOption = this.options[index];
		}
		this.option = newOption;
		return [this.option];
	}

	getOptions() {
		return this.options;
	}

	getLevel() {
		return this.level;
	}

	checkAnswer(pressedKeys, configuration) {
		let success = false;
		if (configuration.get(pressedKeys[0])) {
			success = Object.values(configuration.get(pressedKeys[0])).includes(
				this.option
			); // se fija si la tecla apretada tiene el atributo pedido
		}
		if (!success) {
			this.updateMap(this.option);
		}
		return success;
	}

	updateMap(option) {
		if (localStorage.getItem("errors")) {
			let map = JSON.parse(localStorage.errors);
			map[option.string]
				? map[option.string]++
				: (map[option.string] = 1);
			localStorage.setItem("errors", JSON.stringify(map));
		}
	}

	getText() {
		throw new Error("los metodos abstractos no tienen implementacion");
	}
}
