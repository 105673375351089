export default function log(msg) {
    const fecha = new Date();
    const dia = fecha.getDate();
    const mes = fecha.getMonth() + 1; // Los meses en JavaScript se indexan desde 0, por lo tanto se le suma 1 al resultado.
    const anio = fecha.getFullYear();
    const horas = fecha.getHours() < 10 ? '0' + fecha.getHours() : fecha.getHours();
    const minutos = fecha.getMinutes() < 10 ? '0' + fecha.getMinutes() : fecha.getMinutes();
    const segundos = fecha.getSeconds() < 10 ? '0' + fecha.getSeconds() : fecha.getSeconds();
    const fechaString = `${dia}/${mes}/${anio} ${horas}:${minutos}:${segundos}`;
    msg = `${fechaString}: ${msg}`;
	updateLog(msg);
}

function updateLog(msg) {
	let log = localStorage.getItem("log");
	log = log ? log + "\n" + msg : msg;
	localStorage.setItem("log", log);
}
