import React from 'react';
import Select from 'react-select';
import { useContext } from 'react';
import ConfigContext from '../contexts/ConfigContext';

const keysToSpanish = {
  'Space': 'Espacio',
  'ArrowDown': 'Flecha Abajo',
  'ArrowRight': 'Flecha Derecha',
  'ArrowLeft': 'Flecha Izquierda',
  'ArrowUp': 'Flecha Arriba',
  'KeyW': 'Tecla W',
  'KeyA': 'Tecla A',
  'KeyS': 'Tecla S',
  'KeyD': 'Tecla D',
  'KeyF': 'Tecla F',
};

export default function Selector({ items, pressKey }) {
  const { addItem, configuration, removeItem } = useContext(ConfigContext);

  const handleChange = (value) => {
    value ? addItem(pressKey, value.value) : removeItem(pressKey);
  };

  return (
    <div className="flex items-baseline mb-2">
      <label className="text-lg mr-3 w-2/3">Seleccione el elemento para {keysToSpanish[pressKey] || pressKey}</label>
      <Select
        onChange={handleChange}
        options={items?.map((x) => ({
          label: x.nombre.string,
          value: x,
        }))}
        defaultValue={
          configuration.get(pressKey)
            ? { label: configuration.get(pressKey).nombre.string, value: configuration[pressKey] }
            : undefined
        }
        isClearable={true}
        className="w-1/3 pl-2"
      />
    </div>
  );
}
