import React, { useState } from 'react';

export default function ElementVisualizer({ items, setItems }) {
  const [expandedItem, setExpandedItem] = useState(null);

  const handleToggle = (itemName) => {
    setExpandedItem(expandedItem === itemName ? null : itemName);
  };

  const handleDelete = async (itemName) => {
    const token = localStorage.getItem("token");
    await fetch(`${process.env.REACT_APP_API_URL}${itemName}`, {
      method: "DELETE",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }).then(() =>
      setItems(items.filter((elemento) => itemName !== elemento.nombre.string))
    );
  };

  return (
    <ul className="space-y-4">
      {items.map((item) => (
        <li key={item.nombre.string} className="border rounded-lg shadow-sm">
          <div
            className="flex justify-between items-center p-4 cursor-pointer bg-gray-200 hover:bg-gray-300"
            onClick={() => handleToggle(item.nombre.string)}
          >
            <h6 className="text-lg font-medium">{item.nombre.string}</h6>
            <button
              type="button"
              className="bg-red-500 text-white px-3 py-1 rounded-sm text-sm"
              onClick={(e) => {
                e.stopPropagation();
                handleDelete(item.nombre.string);
              }}
            >
              Eliminar
            </button>
          </div>
          {expandedItem === item.nombre.string && (
            <div className="p-4 bg-gray-50">
              <p><strong>Categoria:</strong> {item.categoria.string}</p>
              <p><strong>Forma:</strong> {item.forma.string}</p>
              <p><strong>Color:</strong> {item.color.string}</p>
            </div>
          )}
        </li>
      ))}
    </ul>
  );
}
