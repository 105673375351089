import React from "react";
import Select from "react-select";

export default function LevelSelector(props) {
	const levels = [
		{ label: "Nivel 1", value: 1 },
		{ label: "Nivel 2", value: 2 },
		{ label: "Nivel 3", value: 3 },
		{ label: "Nivel 4", value: 4 },
		{ label: "Nivel 5", value: 5 },
	];

	return (
		<div className="mb-6 w-full">
			<label className="block text-lg font-semibold mb-2 text-center">Seleccione el nivel deseado</label>
			<Select
				className="w-full"
				options={levels}
				onChange={props.handleChange}
				defaultValue={levels[0]}
				theme={(theme) => ({
					...theme,
					borderRadius: '0.375rem',
					colors: {
						...theme.colors,
						primary25: '#e5e7eb',
						primary: '#3b82f6',
					},
				})}
			/>
		</div>
	);
}
