import { Route, Routes } from "react-router-dom";
import { ConfigProvider } from "./contexts/ConfigContext";
import Home from "./pages/Home";
import Navbar from "./components/NavBar";
import Configuration from "./components/Configuration";
import ItemForm from "./components/ItemForm";
import LoginForm from "./components/LoginForm";
import SignUpForm from "./components/SignUpForm";
import PrivateRoute from "./components/PrivateRoute";
import Footer from "./components/Footer";
import UserList from "./components/UserList";

function App() {
	return (
		<div className="flex flex-col min-h-screen bg-gradient">
			<ConfigProvider>
				<Navbar className='bg-white shadow-2xl' />
				<main className="flex-1 bg-gradient-to-b from-blue-200 to-blue-50">
					<Routes>
						<Route path="/" element={<Home />} />
						<Route path="/addelement" element={<PrivateRoute><ItemForm /></PrivateRoute>} />
						<Route path="/configuration" element={<PrivateRoute><Configuration /></PrivateRoute>} />
						<Route path="/users" element={<PrivateRoute><UserList /></PrivateRoute>} />
						<Route path="/login" element={<LoginForm />} />
						<Route path="/register" element={<SignUpForm />} />
					</Routes>
				</main>
				<Footer />
			</ConfigProvider>
		</div>
	);
}

export default App;
