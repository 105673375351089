import React from "react";
import Game from "../components/Game";
import "./Home.css"

export default function Home() {
	return (
		<div className="container mx-auto px-6 py-8">
			<h1 className="text-3xl font-bold underline">YACT - Herramienta de Estimulación Cognitiva</h1>
			<Game />
		</div>
	)
}
