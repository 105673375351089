import React from 'react';

export default function Counter({ correct, incorrect }) {
  return (
    <div className="bg-gray-100 border border-gray-300 rounded-lg p-4 mt-4 shadow-sm w-full flex justify-between">
      <p className="text-lg font-medium text-green-600">
        Correctas: <span className="font-bold text-green-800">{correct}</span>
      </p>
      <p className="text-lg font-medium text-red-600">
        Incorrectas: <span className="font-bold text-red-800">{incorrect}</span>
      </p>
    </div>
  );
}
