let audio = new Audio();

export const playAudio = (options, onStart, onEnd) => {
  audio.current = new Audio(options[0].audio);
  onStart();
  audio.current.play().then(() => {
    playNextAudio(1, options, onEnd);
  });
};

export const playNextAudio = (index, options, onEnd) => {
  audio.current.onended = () => {
    if (index < options.length) {
      audio.current = new Audio(`https://res.cloudinary.com/dvkqvfep4/video/upload/tesis/luego`);
      audio.current.play().finally(() => {
        audio.current.onended = () => {
          audio.current = new Audio(options[index].audio);
          audio.current.play().then(() => playNextAudio(index + 1, options, onEnd));
        };
      });
    } else {
      onEnd();
    }
  };
};

export const playIncorrectAudio = (onStart, onEnd) => {
  onStart();
  audio.current = new Audio(`https://res.cloudinary.com/dvkqvfep4/video/upload/tesis/incorrecto_u75cll`);
  audio.current.play().finally(() => {
    audio.current.onended = () => onEnd();
  });
};
