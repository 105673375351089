import { useState } from "react";
import MicRecorder from "mic-recorder-to-mp3";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";

export default function AudioRecorder(props) {
	const [estado, setEstado] = useState({
		isRecording: false,
		blobURL: "",
		isBlocked: false,
	});
	// eslint-disable-next-line
	const [Mp3Recorder, setMp3Recorder] = useState(
		new MicRecorder({ bitRate: 128 })
	);

	const startRecording = (event) => {
		event.preventDefault();
		if (estado.isBlocked) {
			// Handle the case when recording is blocked (e.g., due to permissions)
		} else {
			Mp3Recorder.start()
				.then(() => {
					setEstado((prevState) => ({
						...prevState,
						isRecording: true,
					}));
				})
				.catch((e) => console.error(e));
		}
	};

	const stopRecording = (event) => {
		event.preventDefault();
		Mp3Recorder.stop()
			.getMp3()
			.then(([buffer, blob]) => {
				const blobURL = URL.createObjectURL(blob);
				saveAudio(blob);
				setEstado((prevState) => ({
					...prevState,
					blobURL: blobURL,
					isRecording: false,
				}));
			})
			.catch();
	};

	const saveAudio = (blob) => {
		const reader = new FileReader();
		reader.readAsDataURL(blob);
		reader.onloadend = function () {
			const base64data = reader.result;
			props.handleAudioChange(props.value, base64data);
		};
	};

	const startPlaying = () => {
		const audio = new Audio(estado.blobURL);
		audio.play();
	};

	return (
		<div className="flex items-center gap-4 p-2">
			<button
				type="button"
				className={`px-4 py-2 text-white rounded-lg shadow-sm focus:outline-none ${estado.isRecording
						? "bg-red-500 hover:bg-red-600"
						: "bg-blue-500 hover:bg-blue-600"
					}`}
				onClick={estado.isRecording ? stopRecording : startRecording}
			>
				{estado.isRecording ? "Parar" : "Grabar"}
			</button>
			<PlayCircleIcon
				fontSize="large"
				className="cursor-pointer text-blue-500 hover:text-blue-600"
				onClick={startPlaying}
			/>
		</div>
	);
}
