import { Fragment, useState, useContext, useEffect } from 'react';
import { Link, Outlet, useLocation } from 'react-router-dom';
import ConfigContext from '../contexts/ConfigContext';
import jwt_decode from 'jwt-decode';

export default function Navbar() {
  const { resetItems, configuration } = useContext(ConfigContext);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isOpen, setIsOpen] = useState(false); // Estado para controlar la visibilidad del menú
  const location = useLocation(); // Hook para obtener la ubicación actual

  useEffect(() => {
    const handleStorageChange = () => {
      const token = window.localStorage.getItem('token');
      const decodedToken = token ? jwt_decode(token) : null;
      if (decodedToken) {
        setIsLoggedIn(true);
        setIsAdmin(decodedToken.admin);
      } else {
        setIsLoggedIn(false);
        setIsAdmin(false);
      }
    };

    window.addEventListener('storage', handleStorageChange);

    // Initial check on mount
    handleStorageChange();

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  useEffect(() => {
    // Cierra el menú al cambiar de ruta
    setIsOpen(false);
  }, [location]);

  function mapToJson(configuration) {
    let result = {};
    for (let [key, value] of configuration) {
      result[key] = value;
    }
    return result;
  }

  async function saveConfiguration() {
    const token = localStorage.getItem('token');
    await fetch(`${process.env.REACT_APP_API_URL}config`, {
      method: 'PUT',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(mapToJson(configuration)),
    });
  }

  async function saveLog() {
    const token = localStorage.getItem('token');
    const log = localStorage.getItem('log');
    await fetch(`${process.env.REACT_APP_API_URL}log`, {
      method: 'PUT',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ log: log }),
    });
  }

  function saveErrors() {
    const errors = localStorage.getItem('errors');
    const token = localStorage.getItem('token');
    fetch(`${process.env.REACT_APP_API_URL}`, {
      method: 'PUT',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: errors,
    });
  }

  async function logOut() {
    saveErrors();
    await saveConfiguration();
    await saveLog();
    resetItems();
    localStorage.removeItem('token');
    localStorage.removeItem('errors');
    localStorage.removeItem('log');
    window.dispatchEvent(new Event('storage'));
    window.location.reload();
  }

  const handleLinkClick = () => {
    setIsOpen(false); // Cierra el menú al hacer clic en un enlace
  };

  const renderMenu = isLoggedIn ? (
    <ul className="flex flex-col lg:flex-row lg:space-x-4 p-4 lg:p-0">
      {isAdmin ? (
        <li>
          <Link className="text-dark text-sm font-semibold" to="/users" onClick={handleLinkClick}>
            Usuarios
          </Link>
        </li>
      ) : (
        <li>
          <Link className="text-dark text-sm font-semibold" to="/configuration" onClick={handleLinkClick}>
            Configuración
          </Link>
        </li>
      )}
      <li>
        <Link className="text-dark text-sm font-semibold" to="/addelement" onClick={handleLinkClick}>
          Agregar Elemento
        </Link>
      </li>
      <li>
        <button className="text-dark text-sm font-semibold" onClick={() => { logOut(); handleLinkClick(); }}>
          Cerrar Sesión
        </button>
      </li>
    </ul>
  ) : (
    <ul className="flex flex-col lg:flex-row lg:space-x-4 p-4 lg:p-0">
      <li>
        <Link className="text-dark text-sm font-semibold" to="/login" onClick={handleLinkClick}>
          Iniciar Sesión
        </Link>
      </li>
      <li>
        <Link className="text-dark text-sm font-semibold" to="/register" onClick={handleLinkClick}>
          Registrarse
        </Link>
      </li>
    </ul>
  );

  return (
    <Fragment>
      <nav className="flex items-center justify-between bg-white shadow-2xl p-4">
        <Link className="text-dark text-xl font-semibold" to="/">
          Jugar
        </Link>
        <button
          className="lg:hidden p-2"
          type="button"
          aria-controls="navbarSupportedContent"
          aria-expanded={isOpen}
          onClick={() => setIsOpen(!isOpen)}
        >
          <span className="sr-only">Open main menu</span>
          <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7"></path>
          </svg>
        </button>
        <div className={`lg:flex lg:items-center lg:space-x-4 ${isOpen ? 'block' : 'hidden'}`} id="navbarSupportedContent">
          {renderMenu}
        </div>
      </nav>
      <Outlet />
    </Fragment>
  );
}
