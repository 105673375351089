import { React, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import ConfigContext from "../contexts/ConfigContext";

export default function LoginForm() {
  const [data, setData] = useState({
    user: "",
    password: "",
  });

  const [isValid, setIsValid] = useState(true);

  const { setPrevConfiguration } = useContext(ConfigContext);
  const navigate = useNavigate();

  function handleChange(event) {
    setData((prevData) => {
      return { ...prevData, [event.target.name]: event.target.value };
    });
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    const res = await fetch(`${process.env.REACT_APP_API_URL}login`, {
      method: "POST",
      mode: "cors",
      body: JSON.stringify(data),
      headers: { "Content-type": "application/json; charset=UTF-8" },
    });
    if (res.status === 200) {
      const json = await res.json();
      localStorage.setItem("token", json.token);
      localStorage.setItem("errors", JSON.stringify(json.errores));
      localStorage.setItem("log", "");
      const jsonConfiguration = json.configuracion;
      const configuration = new Map(Object.entries(jsonConfiguration));
      setPrevConfiguration(configuration);
      window.dispatchEvent(new Event("storage"));
      navigate("/");
    } else {
      setIsValid(false);
    }
  };

  return (
    <div className="max-w-md mx-auto p-6 bg-white shadow-lg rounded-lg mt-10">
      <h1 className="text-2xl font-semibold mb-6 text-center">Inicia sesión</h1>
      <form className="space-y-4" onSubmit={handleSubmit}>
        <div>
          <input
            type="text"
            placeholder="Usuario"
            onChange={handleChange}
            name="user"
            value={data.user}
            className="w-full px-4 py-2 border rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>
        <div>
          <input
            type="password"
            placeholder="Contraseña"
            onChange={handleChange}
            name="password"
            value={data.password}
            className="w-full px-4 py-2 border rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>
        <button
          type="submit"
          className={`w-full px-4 py-2 text-white font-semibold rounded-lg shadow-md ${isValid ? 'bg-blue-500 hover:bg-blue-600' : 'bg-gray-400 cursor-not-allowed'}`}
          disabled={!data.user || !data.password}
        >
          Login
        </button>
        <p className="mt-3 text-center text-sm">
          ¿No tienes usuario? <a href="/register" className="text-blue-500 hover:underline">Crear usuario</a>
        </p>
        {!isValid && (
          <div className="mt-4 p-4 bg-red-100 text-red-800 border border-red-200 rounded">
            Usuario o contraseña incorrectos
          </div>
        )}
      </form>
    </div>
  );
}
