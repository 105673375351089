import { React, useState } from "react";
import AudioRecorder from "./AudioRecorder";

export default function ItemForm() {
  const [data, setData] = useState({
    nombre: { string: "", audio: "" },
    categoria: { string: "", audio: "" },
    forma: { string: "", audio: "" },
    color: { string: "", audio: "" },
  });
  const [alreadyExists, setAlreadyExists] = useState(false);
  const isValid = data.nombre && data.categoria && data.forma && data.color;

  const handleChange = (event) => {
    const { name, value } = event.target;
    setData((prevState) => ({
      ...prevState,
      [name]: {
        ...prevState[name],
        string: value,
      },
    }));
  };

  const handleAudioChange = (name, value) => {
    setData((prevState) => ({
      ...prevState,
      [name]: {
        ...prevState[name],
        audio: value,
      },
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const res = await fetch(`${process.env.REACT_APP_API_URL}`, {
      method: "POST",
      mode: "cors",
      body: JSON.stringify(data),
      headers: { "Content-type": "application/json; charset=UTF-8" },
    });
    if (res.status === 200) {
      alert("Se ha guardado el elemento");
      setAlreadyExists(false);
    } else setAlreadyExists(true);
  };

  return (
    <div className="max-w-md mx-auto p-6 bg-white shadow-lg rounded-lg mt-10">
      <h1 className="text-2xl font-semibold mb-6 text-center">Agregar un nuevo elemento</h1>
      <form className="space-y-4" onSubmit={handleSubmit}>
        <div className="flex items-center gap-4">
          <input
            type="text"
            placeholder="Nombre de elemento"
            onChange={handleChange}
            name="nombre"
            value={data.nombre.string}
            className="w-full px-4 py-2 border rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
          <AudioRecorder value="nombre" handleAudioChange={handleAudioChange} />
        </div>
        <div className="flex items-center gap-4">
          <input
            type="text"
            placeholder="Categoría"
            onChange={handleChange}
            name="categoria"
            value={data.categoria.string}
            className="w-full px-4 py-2 border rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
          <AudioRecorder value="categoria" handleAudioChange={handleAudioChange} />
        </div>
        <div className="flex items-center gap-4">
          <input
            type="text"
            placeholder="Forma"
            onChange={handleChange}
            name="forma"
            value={data.forma.string}
            className="w-full px-4 py-2 border rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
          <AudioRecorder value="forma" handleAudioChange={handleAudioChange} />
        </div>
        <div className="flex items-center gap-4">
          <input
            type="text"
            placeholder="Color"
            onChange={handleChange}
            name="color"
            value={data.color.string}
            className="w-full px-4 py-2 border rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
          <AudioRecorder value="color" handleAudioChange={handleAudioChange} />
        </div>
        <button
          className={`w-full px-4 py-2 text-white font-semibold rounded-lg shadow-md ${isValid ? 'bg-blue-500 hover:bg-blue-600' : 'bg-gray-400 cursor-not-allowed'}`}
          disabled={!isValid}
          type="submit"
        >
          Añadir
        </button>
        {alreadyExists && (
          <div className="mt-4 p-4 bg-red-100 text-red-800 border border-red-200 rounded">
            El elemento ya existe
          </div>
        )}
      </form>
    </div>
  );
}
