import React, { useContext } from 'react';
import ConfigContext from '../contexts/ConfigContext';

export default function SelectMaxDifficulty() {
  const { maxDifficulty, setMaxDifficulty } = useContext(ConfigContext);

  const handleSliderChange = (event) => {
    const newMaxDifficulty = parseInt(event.target.value, 10);
    setMaxDifficulty(newMaxDifficulty);
  };

  return (
    <div className="p-4 w-full">
      <label
        htmlFor="maxDifficulty"
        className="block text-lg font-medium text-gray-700 mb-2"
      >
        Dificultad máxima: {maxDifficulty}
      </label>
      <input
        type="range"
        id="maxDifficulty"
        min="1"
        max="10"
        step="1"
        value={maxDifficulty}
        onChange={handleSliderChange}
        className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer range-slider"
      />
      <style jsx>{`
        .range-slider::-webkit-slider-thumb {
          appearance: none;
          width: 20px;
          height: 20px;
          background: #4a90e2;
          cursor: pointer;
          border-radius: 50%;
          box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
        }

        .range-slider::-moz-range-thumb {
          width: 20px;
          height: 20px;
          background: #4a90e2;
          cursor: pointer;
          border-radius: 50%;
          box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
        }
      `}</style>
    </div>
  );
}
