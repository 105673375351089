import { React, useEffect, useState } from 'react';
import Selector from './Selector';
import Log from './Log';
import ElementVisualizer from './ElementVisualizer';

export default function Configuration() {
  const [items, setItems] = useState([]);

  const entradas = [
    'Space',
    'ArrowDown',
    'ArrowRight',
    'ArrowLeft',
    'ArrowUp',
    'KeyW',
    'KeyA',
    'KeyS',
    'KeyD',
    'KeyF',
  ];

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}`, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((data) => data.json())
      .then((res) => setItems(res));
  }, []);

  return (
    <div className="container mx-auto px-4 pb-24"> {/* Añade pb-24 para el espaciado inferior */}
      <div className="flex flex-col md:flex-row gap-6 mt-4">
        <div className="flex-1">
          <div className="space-y-4">
            <h5 className="text-xl mb-4">Configurar Elementos</h5>
            {entradas.map((entrada) => (
              <Selector key={entrada} pressKey={entrada} items={items} />
            ))}
          </div>
        </div>
        <div className="w-full md:w-1/3">
          <h5 className="text-xl mb-4">Elementos actuales</h5>
          <ElementVisualizer items={items} setItems={setItems} />
        </div>
      </div>
      <hr className="my-6 border-gray-300" />
      <h5 className="text-xl font-semibold mb-4">Resultados de las sesiones</h5>
      <Log />
    </div>
  );
}
